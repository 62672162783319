@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

@font-face {
  font-family: "Cute-Dino";
  src: url("./asset/font/Cute-Dino.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: calc(0.35rem + 0.4vw);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Cute-Dino", sans-serif;
  background-color: #ffc8dd;
}

.header {
  padding-top: 2.3125rem;
  margin-bottom: 3.5rem;

  @media (max-width: 768px) {
    padding-top: 4.5rem;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14.375rem;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}

.header-image {
  height: 100%;
  object-fit: contain;
  width: max-content;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 500px) {
    width: 70%;
    padding: 0 1rem;
  }
}

.logo-image {
  height: 2.5rem;
  object-fit: contain;
  position: absolute;
  top: 3rem;
  right: 5.875rem;

  @media (max-width: 768px) {
    display: none;
  }
}

.header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 1rem 0.625rem;
  margin-left: 0.875rem;

  img {
    cursor: pointer;
    width: 4.375rem;
    height: 4.375rem;

    @media (max-width: 768px) {
      width: 6rem;
      height: 6rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid {
  display: flex;
  margin: auto;
  width: 100%;
  gap: 10%;

  img.hero-image {
    aspect-ratio: 339/500;
    border-radius: 8px;
    max-width: 31.25rem;
    object-fit: contain;
    transform: translateY(5rem);
    position: relative;
    z-index: -1;
  }

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    img.hero-image {
      transform: translateY(0);
    }
  }
}

.wave-image {
  width: 100%;
  background-image: linear-gradient(
    180deg,
    #ffc8dd00 0%,
    #ffc8dd 50%,
    #ffc0cb 50%,
    #ffc0cb 100%
  );
  height: 10.5625rem;

  @media (max-width: 1100px) {
    margin-top: -5rem;
  }
}

.about {
  background-color: #ffc0cb;
  margin-top: -0.625rem;

  .content {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;

    @media (max-width: 768px) {
      padding-top: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .about-text {
    display: flex;
    align-items: center;
    margin-top: 2.625rem;
    margin-bottom: 8.375rem;
    gap: 5rem;
    padding: 0 2rem;

    @media (max-width: 768px) {
      padding-left: 0;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .about-image {
    margin-top: 1.9375rem;
    max-width: 36.3125rem;
    width: 100%;
    object-fit: contain;
    display: block;
  }

  .about-text-image {
    max-width: 57.6875rem;
    width: 100%;
    object-fit: contain;
    @media (max-width: 768px) {
      margin-left: 0rem;
    }
  }

  .julia-image {
    max-width: 21.1875rem;
    width: 100%;
    object-fit: contain;
    display: block;

    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }
}

.about-wave-image {
  width: 100%;
  margin-top: -4rem;
  height: 10.5625rem;

  @media (max-width: 768px) {
  }
}

.tokenomics {
  margin-top: -5.5rem;
  background-image: url("./asset/redbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 4rem;

  .token-image {
    padding-top: calc(2.625rem + 4.5rem);
    margin-bottom: 2.3125rem;
    max-width: 39.5rem;
    width: 100%;
  }

  .token-text-image {
    max-width: 46.0625rem;
    width: 100%;
    margin-left: 4rem;
    margin-bottom: 4.5rem;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  img {
    display: block;
  }

  @media (max-width: 500px) {
    margin-top: -2.2rem;
  }
}

.tokenomics-wave-image {
  width: 100%;
  margin-top: -5rem;
  height: 10.5625rem;

  @media (max-width: 768px) {
    margin-top: -2.2rem;
  }
}

.footer {
  .bar {
    height: 3.125rem;
    background-color: #fff;
    width: 100%;
  }
}

.createMe {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  .pad {
    padding-bottom: 4.1875rem;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4.125rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .save-image {
    width: 100%;
    margin-top: 2rem;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 10.875rem;
      border-radius: 1.25rem;
      text-transform: uppercase;
      font-size: 2rem;
      padding-top: 0.5rem;
      font-family: "Just Another Hand", cursive;
      color: #fff;
      background-color: #ff4d8b;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .main-box {
    width: 21rem;
    margin-top: 4rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    outline: 0.625rem solid black;
    position: relative;
    aspect-ratio: 1/1;

    .bg-image,
    .character {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      z-index: 1;
    }

    .character {
      z-index: 2;
    }

    .bg {
      background-size: 101% 101%;
      background-position-y: -0.5px;
      z-index: 1;
    }

    .clothing {
      z-index: 3;
    }

    .accessories {
      z-index: 4;
    }

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 4rem;
      width: 30rem;
    }
  }

  .side-boxes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section {
    margin-bottom: 1rem;
  }

  .boxes {
    display: flex;
  }

  .text-img {
    height: 1.75rem;
  }

  .box {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 0.75rem;
    border: 0.25rem solid #ff97e3;
    margin-right: 0.625rem;
    cursor: pointer;
    padding: 0.25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0.5rem;
    }

    &.active {
      background-color: #ff97e3;
    }

    @media (max-width: 768px) {
      width: 5.5rem;
      height: 5.5rem;
    }
  }

  .createMe-img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  background-color: #ff7ba9;

  .footer-text {
    font-size: 1.25rem;
    color: #fff;
    padding-bottom: 1.25rem;
    padding-top: 1.5625rem;
    text-align: center;
    font-family: "K2D", sans-serif;
  }

  .social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
    max-width: 78.75rem;
    width: 100%;
    margin: auto;
    padding-top: 1.875rem;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;

      img.social-icon {
        width: 5.125rem;
        height: 5.125rem;
      }
    }

    @media (max-width: 768px) {
      a img:not(.social-icon) {
        height: 3.125rem;
        margin-bottom: 0.5rem;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
    }
  }
}

.story {
  background-color: #ffc8dd;
  padding-bottom: 5.5rem;

  .my-story {
    max-width: 29.875rem;
    width: 100%;
    margin: auto;
    display: block;
    padding-top: 1rem;
  }

  .story-content {
    &:first-of-type {
      transform: translateY(-6rem);
    }

    &:nth-child(3) {
      transform: translateY(-3rem);
    }

    &:nth-child(7) {
      transform: translateY(-2rem);
    }

    &:nth-child(9) {
      transform: translateY(-4rem);
    }

    .title {
      font-size: 3rem;
      color: #fff;
      font-family: "Just Another Hand", cursive;
      margin-bottom: 0.5rem;
    }
    .text {
      margin-bottom: 0.25rem;
      font-family: "Just Another Hand", cursive;
      color: #fff;
      font-size: 1.5rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    img,
    video {
      width: 15.25rem;
      height: 23.1875rem;
      object-fit: cover;
    }
  }

  .line {
    align-self: center;

    &.line1 {
      width: 9rem;
    }

    &.line2 {
      width: 7.25rem;
    }

    &.line3 {
      width: 7.9375rem;
    }

    &.line4 {
      width: 5rem;
    }
  }

  .story-grid {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2rem;
    padding-top: 5rem;

    @media (max-width: 1100px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .tokenomics {
    margin-top: 0;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .tokenomics-wave-image {
    transform: translateY(-3rem);
  }
  .about-wave-image {
    transform: translateY(5.5rem);
    margin-top: -10rem;
  }
}

.about-text-image {
  max-width: 57.6875rem;

  p {
    color: #ff97e3;
    font-size: 2.125rem;
    text-shadow: -0.1875rem 0.1875rem 0.1875rem #f86396,
      0.1875rem -0.1875rem 0.1875rem #f86396,
      0.1875rem 0.1875rem 0.1875rem #f86396,
      -0.1875rem -0.1875rem 0.1875rem #f86396;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.social-text {
  font-size: 2.25rem;
  text-transform: uppercase;
  color: #ff7ba9;
  font-size: 2.125rem;
  text-shadow: -0.125rem 0.125rem 0.125rem #fff,
    0.125rem -0.125rem 0.125rem #fff, 0.125rem 0.125rem 0.125rem #fff,
    -0.125rem -0.125rem 0.125rem #fff;

  @media (max-width: 768px) {
    font-size: 2.75rem;
    text-align: center;
  }
}
.Toastify__toast-container {
  @media (min-width: 768px) {
    width: 50vw !important;
  }
}

.story-content {
  transition: transform 0.2s ease-in-out;
  opacity: 0.95;
}

.story-content:hover {
  opacity: 1;
}

.save-image {
  transition: transform 0.2s ease-in-out;
}

.save-image:hover {
  transform: scale(1.1);
}

.header-icon {
  transition: transform 0.2s ease-in-out;
}
.header-icon:hover {
  transform: scale(1.1);
}

.Toastify__toast-body {
  text-align: center;
  width: 100%;
  font-size: 18px;
}

.quick-link:hover {
  transform: scale(1.1);
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.quick-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.8125rem;
  background-color: #ff649a;
  border-radius: 1.25rem;
  width: max-content;
  margin: auto;
  min-height: 2.875rem;
  padding: 0.4375rem 1.8125rem;
  padding-top: 0.625rem;
  margin-bottom: 1.5625rem;

  a {
    color: #fff;
    font-size: 2rem;
    text-decoration: none;
    font-family: "Just Another Hand", cursive;
  }
}

.mob {
  display: none;
}

@media (max-width: 1100px) {
  .story-content {
    position: relative;
    z-index: 1;
    transform: translateY(0) !important;
  }

  .line1 {
    transform: rotate(90deg) !important;
    margin-bottom: 3rem;
    position: relative;
    z-index: 0;
  }

  .line2 {
    transform: rotate(90deg) !important;
    margin-bottom: 3rem;
    position: relative;
    z-index: 0;
  }

  .line3,
  .line4 {
    display: none;
  }

  .story-content {
    .text {
      font-size: 2.5rem !important;
    }

    .title {
      font-size: 4.5rem !important;
    }

    img,
    video {
      width: 24.25rem !important;
      height: 38.75rem !important;
    }
  }

  .mob {
    display: block;
  }
}

.about-text-video {
  max-width: 21.1875rem;
  width: 100%;
  object-fit: cover;
  display: block;
  border-radius: 0.5rem;
  aspect-ratio: 281/456;
  margin-right: 1rem;
}
